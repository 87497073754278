:root { 
  --black: #000000;
  --cats-black: #232323;
  --cats-blue: #7285a5;
  --cats-yellow: #feea7f;
  --mist-gray: #c4c4c4;
  --shark: #262424;
  --white: #ffffff;
 
  --font-size-l: 25px;
  --font-size-m: 20px;
  --font-size-s: 18px;
  --font-size-xl: 30px;
  --font-size-xs: 16px;
  --font-size-xxl: 35px;
  --font-size-xxxl: 70px;
 
  --font-family-montserrat: "Montserrat", Helvetica;
  --font-family-proxima_soft-extrabold: "Proxima Soft-Extrabold", Helvetica;
  --font-family-special_elite: "Special Elite", Helvetica;
}

@font-face {
  font-family: "Proxima Soft-Extrabold";
  src: local("Proxima Soft-Extrabold"),
    url(./static/fonts/ProximaSoft-ExtraBold.woff) format("woff");
}

.montserrat-extra-light-white-18px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 200;
}

.specialelite-normal-white-30px {
  color: var(--white);
  font-family: var(--font-family-special_elite);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.specialelite-normal-white-20px {
  color: var(--white);
  font-family: var(--font-family-special_elite);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.proximasoft-extra-normal-white-70px {
  color: var(--white);
  font-family: var(--font-family-proxima_soft-extrabold);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}

.proximasoft-extra-normal-white-30px {
  color: var(--white);
  font-family: var(--font-family-proxima_soft-extrabold);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.montserrat-extra-light-white-20px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 200;
}

.proximasoft-extra-normal-white-25px {
  color: var(--white);
  font-family: var(--font-family-proxima_soft-extrabold);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.proximasoft-extra-normal-black-30px {
  color: var(--black);
  font-family: var(--font-family-proxima_soft-extrabold);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.proximasoft-extra-normal-black-16px {
  color: var(--black);
  font-family: var(--font-family-proxima_soft-extrabold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.border-5px-white {
  border: 5px solid var(--white);
}

.border-5px-black {
  border: 5px solid var(--black);
}

.border-4px-white {
  border: 4px solid var(--white);
}
